<template lang="pug">
header.bg-white.py-5
	b-container
		b-row.justify-content-center
			b-col(col="", md="10").my-4
				h5.text-center.font-weight-light {{ $appName }}.cl - La manera más segura y fácil de
					span.font-weight-bold  comprar
					|  o
					span.font-weight-bold  vender
					|  tu auto.
				h1.text-center.py-2 ¿Qué clase de vehículo estás buscando hoy?
	#bodyworks
		carousel(
			:per-page-custom="[[320, 3],[480, 3],[768, 4],[992, 5],[1024, 7]]",
			:navigation-enabled="true",
			:navigation-click-target-size="0",
			navigation-next-label="&\#8250",
			navigation-prev-label="&\#x2039",
			:min-swipe-distance="0",
			:pagination-padding="3",
			pagination-active-color="#e8c541"
		).types
			slide(v-for="bodywork in bodyworks", :key="bodywork.id")
				router-link(:to="{ name: 'filters', query: { bodywork: bodywork.slug }}")
					.square
						i(:class="bodywork.icon")
					span(v-text="bodywork.name")
	b-row#action-home.justify-content-center.text-center
		b-col(cols="12").mb-5
			h2 ¿Quieres comprar o vender tu auto con crédito?
		b-col(cols="5" md="3")
			router-link(to="/filters?bodywork=all").btn-okcarpremium.btn-lg.btn-block Comprar
		b-col(cols="1")
			.h3.pt-1 O
		b-col(cols="5" md="3")
			router-link(:to="{name:'sell'}").btn-okcarpremium.btn-lg.btn-block Vender
</template>
<script>
	import { mapState, mapActions } from "vuex";
	import { Carousel, Slide } from "vue-carousel";

	export default {
		name: "BodyworksCarousel",
		components: {
			Carousel,
			Slide
		},
		computed: {
			...mapState('bodywork',['bodyworks'])
		},
		mounted() {
			this.getBodyworks();
		},
		methods: {
			...mapActions('bodywork',["getBodyworks"])
		}
	};
</script>
<style lang="scss">
	#bodyworks{
		.card {
				&-body{
					@media (max-width: 768px) {
						padding: .8rem .5rem;
				}
			}
			&-img {
				max-width: 100%;
			}
			&-subtitle {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				font-size: .8rem;
			}
		}
	}
	.VueCarousel-slide-active:hover {
		border: 0;
		box-shadow: none;
	}
	@media screen and (max-width: 576px) {
		button.VueCarousel-navigation-button.VueCarousel-navigation-next {
			display: none !important;
		}
	}
</style>
