<template lang="pug">
header.py-3
  b-container
    b-row.justify-content-center
      b-col.my-4(col="", md="10")
        h2.text-center.py-2 Lo mejor en autos esta llegando
  .text-center.my-5(v-if="loading")
    b-spinner(variant="secondary" label="Spinning")
    p.text-secondary.pb-5 Cargado espere un momento...
  #latestProduct(v-else)
    carousel.new-products-carousel(
      :per-page-custom="[[320, 2],[480, 3],[768, 4],[992, 5],[1024, 5]]"
      :navigation-enabled="true"
      :navigation-click-target-size="0"
      navigation-next-label="&\#8250"
      navigation-prev-label="&\#x2039"
      :min-swipe-distance="0"
      :pagination-padding="3"
      pagination-active-color="#e8c541"
      v-if="latestProduct.length > 0"
    )
      slide(v-for="product in latestProduct" :key="product.id")
        b-link.card-product(href="#", @click="detailedProduct(product)")
          Ribbon(:sold="product.sold" :title="product.label_name" :color="product.label_color" :top="128" :size="0.7")
          b-card(no-body class="overflow-hidden" v-bind:class="{ sold: product.sold }")
            b-card-img-lazy(v-bind="mainProps" :src="getThumbnail()" :srcset="getThumbnail(product.images)")
            b-card-body
              b-card-title.product-title {{ product.title }}
              b-card-sub-title.product-title
                .label Precio
                .price(v-if="product.price") ${{ product.price | formatNumber }}
                .price(v-else) Consultar
              b-card-text.product-subtitle {{ product.description }}
              //b-button.btn-credit(v-if="product.vehicles_products.version" variant="okcarpremium" block size="sm", @click.stop="creditVehicle(product)")
                font-awesome-icon(icon="credit-card")
                |  Solicitar Credito
              b-button.btn-credit(variant="outline-secondary" block size="sm")
                |  Detalle
    p.text-secondary.text-center.my-5(v-else) No se han ingresado vehículos
  footer-navbar-mobile(v-if="(isAdmin||isProfessional)")
</template>
<script>
import { Carousel, Slide } from "vue-carousel";
import { mapState } from "vuex";
import Hashids from "hashids";
import FooterNavbarMobile from "../FooterNavbarMobile";
import Ribbon from "./Ribbon";
export default {
  name: "RecentProducts",
  components: {
    Carousel,
    Slide,
    FooterNavbarMobile,
    Ribbon,
  },
  data() {
    return {
      latestProduct: [],
      urlImg: "",
      Hashids: new Hashids(
        process.env.VUE_APP_HASHIDS_SALT,
        10,
        process.env.VUE_APP_HASHIDS_ALPHABET
      ),
      loading: true,
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: "#ddd",
        width: 320,
        height: 240,
      },
    };
  },
  mounted() {
    this.getLastProduct();
    this.setBaseUrl();
  },
  filters: {
    formatNumber: function (value) {
      if (!value) return "";
      value = value.toString();
      value = value.replace(/\./g, "");
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    cleanString: function (valueString) {
      if (!valueString) return "";
      valueString = valueString.toString();
      const regex = /([#0-9]\u20E3)|[\xA9\xAE\u203C\u2047-\u2049\u2122\u2139\u3030\u303D\u3297\u3299][\uFE00-\uFEFF]?|[\u2190-\u21FF][\uFE00-\uFEFF]?|[\u2300-\u23FF][\uFE00-\uFEFF]?|[\u2460-\u24FF][\uFE00-\uFEFF]?|[\u25A0-\u25FF][\uFE00-\uFEFF]?|[\u2600-\u27BF][\uFE00-\uFEFF]?|[\u2900-\u297F][\uFE00-\uFEFF]?|[\u2B00-\u2BF0][\uFE00-\uFEFF]?|(?:\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDEFF])[\uFE00-\uFEFF]?/g;
      return valueString.replace(regex, "");
    },
  },
  methods: {
    getThumbnail(images) {
      if (Array.isArray(images) && images.length > 0) return images[0].thumbnail;
      else return `https://api.okcarpremium.cl/images/no_image_thumb.jpg`;
    },
    getLastProduct() {
      try {
        this.loading = true;
        this.$axios
          .get("/api/v1/product/last-cars")
          .then(({ data: { data: products } }) => {
            this.latestProduct = products;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => (this.loading = false));
      } catch (e) {
        this.$bvToast.toast("Problemas al cargar las productos :(", {
          title: "Error de carga desde API",
          variant: "danger",
          solid: true,
        });
        this.loading = false;
      }
    },
    detailedProduct(product) {
      if (!product.sold) {
        let slug = product.slug;
        let hashid = this.Hashids.encode(product.id);
        this.$router.push({ path: `/detailed-product/${slug}/${hashid}` });
      }
    },
    setBaseUrl() {
      this.urlImg = this.$axios.defaults.baseURL;
    },
    creditVehicle(product) {
      let slug = product.slug;
      let hashid = this.Hashids.encode(product.id);
      this.$router.push({ path: `/credits/${slug}/${hashid}` });
    },
  },
  computed: {
    ...mapState("auth", ["isAdmin", "isProfessional"]),
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/_variables";

#latestProduct {
  .new-products-carousel {
    .VueCarousel-slide {
      max-height: 100% !important;
      width: 200px;
      a {
        .card {
          overflow: hidden;
          border-radius: 20px;
          &.sold {
            cursor: no-drop;
            .card-img {
              filter: grayscale(1);
            }
            &::before {
              background-color: #000;
              border-radius: 10px;
              color: white;
              content: "VENDIDO";
              font-size: 1.2rem;
              font-weight: 500;
              left: 0;
              margin: 0 auto;
              padding: 5px 20px;
              position: absolute;
              right: 0;
              text-align: center;
              top: 15%;
              transform: rotate(-35deg);
              width: 160px;
              z-index: 1;
            }
          }
          &-body {
            text-align: left;
            padding: 1rem 0.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-height: 1.5;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          &-title {
            font-size: 1em;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: uppercase;
            white-space: nowrap;
            width: 160px;
          }
          &-subtitle {
            width: 160px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0.5rem;
            .label {
              font-size: 0.75rem;
              margin-bottom: 0.3rem;
              text-transform: uppercase;
            }
            .price {
              font-weight: 600;
              font-size: 1.2rem;
            }
            @media (max-width: 1024px) {
              max-width: 160px;
            }
            @media (max-width: 992px) {
              max-width: 170px;
            }
            @media (max-width: 768px) {
              max-width: 160px;
            }
            @media (max-width: 480px) {
              max-width: 150px;
            }
          }
          &-text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-height: 1.5;
            font-weight: 300;
            font-size: 0.95em;
            -webkit-line-clamp: 2;
            margin-bottom: 0.3rem;
            -webkit-box-orient: vertical;
            margin-bottom: 1rem;
            height: 40px;
          }
        }
      }
    }
    .VueCarousel-wrapper {
      &::after,
      &::before {
        background: none;
      }
    }
    .card-title {
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 1.05rem;
    }
  }
}
</style>
